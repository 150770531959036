<script setup>

</script>

<template>
  <v-img
      src="../assets/DumpsterPhotoHouse.png"
      width="100%"
      height="100%"
      max-width="800"
      max-height="1500"
  />
</template>

<style scoped>

</style>