<script setup>

</script>

<template>
  <v-img
      class="posterImage"
      src="../assets/RemovalServicesPoster.png"
      width="100%"
      height="100%"
      max-width="800"
      max-height="1500"
  />
</template>

<style scoped>
.posterImage {
  margin-top: 10%;
}
</style>